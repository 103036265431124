@import './tailwind-variables.scss';
// Variables texto
$sora-700: 'SoraBold';
$sora-600: 'SoraSemiBold';
$sora-400: 'SoraRegular';
$arial-700: 'ArialBold';
$arial-400: 'ArialRegular';
$poppins-600: 'PoppinsSemiBold';

// Colores primarios
$primary-color-1: $colors-primary;
$primary-color-2: $colors-secondary;
$primary-color-3: $colors-tertiary;

// Colores secundarios
$secondary-color-1: $colors-accent;

// Colores semanticos
$semantic-color-1: $colors-semantic-1;
$semantic-color-2: $colors-semantic-2;
$semantic-color-3: $colors-semantic-3;
$semantic-color-4: $colors-semantic-4;
$semantic-color-5: $colors-semantic-5;

// Colores neutros
$neutral-color-1: $colors-neutral-1;
$neutral-color-2: $colors-neutral-2;
$neutral-color-3: $colors-neutral-3;
$neutral-color-4: $colors-neutral-4;
$neutral-color-5: $colors-neutral-5;
$neutral-color-6: $colors-neutral-6;
$neutral-color-7: $colors-neutral-7;
$neutral-color-8: $colors-neutral-8;

// Variables de tamaño
$height-header: 88px;
$width-details-container: 1164px;

@function calcWidth($base, $width) {
    @return calc(($width * 100%) / $base);
}
