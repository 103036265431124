@use 'sass:color';
@use '../../styles/variables.scss';

.header-component {

    .profile-container {

        .notification {
            display: flex;
            width: 16px;
            height: 16px;
            justify-content: center;
            align-items: center;
            position: relative;
            right: -27px;
            top: -11px;
            border-radius: 100px;
            background-color: variables.$semantic-color-1;
            //color: #FFF;
            text-align: center;
            font-family: variables.$arial-400;
            font-size: 11px;
        }

        .profile {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 31px;
            border-radius: 29px;
            margin-left: 16px;
        }

        .chevron {
            margin-left: 17px;
            cursor: pointer;
        }

        .rotate {
            transform: rotate(180deg);
            cursor: pointer;
        }

        .tooltip-header {
            display: flex;
            padding: 16px 24px;
            border-radius: 6px;
            border: 1px solid variables.$neutral-color-2;
            background-color: #FFF;
            box-shadow: 6px 4px 12px 0px rgba(62, 35, 226, 0.10);
            opacity: 1;
            z-index: 1;
        }
    }
}