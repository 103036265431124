@import '../../styles/variables.scss';

.navigation-bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 68px;
    border-bottom: 1px solid fade-out($primary-color-1, 0.90);

    .previous-page {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 27px;
        border: 1px solid fade-out($primary-color-1, 0.80);
        //background: fade-out($primary-color-1, 0.90);
        cursor: pointer;
    }
}