@import './styles/variables.scss';

#my-body{
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.App {
    display: flex;
    height: 100vh;
    margin: 0 auto;
    text-align: center;
    font-family: $sora-400;
    background-color: #FFF;
}

.input-money-sign {
    width: 85px;
    background-image: url("data:image/svg+xml;utf8,<svg class='svg-icon' xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='gray' font-size='12' font-family='arial'>$</text></svg>");
    background-repeat: no-repeat;
    background-position:10px 45%;
    background-size: 100px;
    padding-left: 25px !important;

}

.flex-0-5{
    flex: 0.5 1 0%;
}

.flex-1-5{
    flex: 1.5 1 0%;
}

.flex-2{
    flex: 2 1 0%;
}

.flex-2-5{
    flex: 2.5 1 0%;
}

.flex-3-5{
    flex: 3.5 1 0%;
}

.card{
    box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;
    padding: 16px;
    background-color: #FFF;
}

