@import '../../styles/variables.scss';

.search-input-container {
    display: flex;
    width: 526px;
    height: 48px;
    box-sizing: border-box;
    padding: 10px 16px;
    align-items: center;
    gap: 14px;
    background-color: #FFF;
    border-radius: 100px;

    img {
        width: 18px;
        height: 18px;
    }

    input {
        width: 100%;
        border: none;
        color: $primary-color-1;
        font-family: $arial-400;
        font-size: 16px;
        line-height: 23.9px;
        outline: none;
    }
    input::placeholder {
        color: $primary-color-1;
    }

    &.form {
        width: 100%;
        flex-direction: row-reverse;
        border: 1px solid $neutral-color-3;

        img {
            stroke: #ACACAC;
        }
        input::placeholder {
            color: $neutral-color-4;
        }
    }
}