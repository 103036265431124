@use '../../styles/variables.scss';

.sidenav-component {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px 16px 32px 16px;
    box-sizing: border-box;
    border-right: 0.5px solid #E0E0E0;
    gap: 10px;

    .item-option {
        display: flex;
        width: 231px;
        min-height: 62px;
        padding: 0 24px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        cursor: pointer;
        border-bottom: 3px solid transparent;

        .container {
            display: flex;
            align-items: center;
            gap: 16px;

            p {
                font-family: variables.$sora-700;
                //color: variables.$neutral-color-5
            }

            .with-submenu {
                display: flex;
                align-items: center;
                gap: 16px;
                width: 171px;
            }

            .rotate-chevron {
                transform: rotate(180deg);
            }
        }
        .with-submenu {
            gap: 0;
        }

        &:hover/* ,
        &.select-item */{
            border-bottom: 3px solid variables.$primary-color-3;
        }
    }


    .suboption-container {
        width: 230px;
        //background-color: variables.$neutral-color-1;

        .item-suboption {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            box-sizing: border-box;
            gap: 10px;
            height: 68px;
            cursor: pointer;

            .container {
                display: flex;
                align-items: center;
                gap: 16px;
    
                p {
                    font-family: variables.$sora-700;
                    //color: variables.$neutral-color-5
                }
            }

            &.selected {
                p {
                    //color: variables.$primary-color-1;
                }
            }
        }

        
    }
}

.img-sidemenu{
   right: -38px;
}


svg {
    width: 25px;
    height: 25px;
}