@import '../../styles/variables.scss';

.input-form {
    input {
        height: 56px;
        width: 100%;
        font-size: 16px;
        padding: 0px 16px 0 16px;
        font-family: $arial-400;
        color: $neutral-color-7;
        background-color: #FFF;
        border: 1px solid $neutral-color-3;
        border-radius: 100px;
        box-sizing: border-box;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $neutral-color-3;
        }

        &.valid {
            background-color: $neutral-color-2;
        }
        &:disabled{
            background-color: $neutral-color-3;
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}