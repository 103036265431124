@import '../../styles/variables.scss';

.profile-menu-container {

    .menu {
        display: flex;
        padding: 16px 0px;
        align-items: flex-start;
        gap: 17px;
        align-self: stretch;
        cursor: pointer;

        p {
            color: $neutral-color-8;
            font-family: $arial-400;
            font-size: 14px;
            line-height: normal;
        }

        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 149px;
            p:first-child {
                font-family: $arial-700;
            }
            .profile-email {
                font-size: 12px;
                word-wrap: break-word;
                width: 100%;
                white-space: normal;
                text-align: left;
            }
        }
    }

}