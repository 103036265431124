@use 'sass:color';
@use '../../styles/variables.scss';

.tabs-container {
    display: flex;
    border-radius: 8px 8px 0px 0px;
    background: variables.$colors-specialBG;
    width: auto;
    
    .item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 8px 0 16px;
        cursor: pointer;
        height: 60px;
        border-bottom: 2px solid transparent;

        img {
            width: 18px;
            height: 18px;
        }
        
        p {
            color: variables.$neutral-color-4;
            font-family: variables.$arial-700;
            font-size: 14px;
            line-height: normal;
        }
    }

    .item:hover, .selected {
        border-bottom: 2px solid variables.$primary-color-3;
        p {
            color: variables.$primary-color-1;
        }
    }
}