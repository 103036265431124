@use '../../styles/variables.scss';

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    //width: 1440px;
    height: 100%;
    z-index: 100;
    gap: 24px;
    background: transparentize($color: variables.$neutral-color-1, $amount: 0.05);

    h1 {
        width: 371px;
        color: #000;
        text-align: center;
        font-family: variables.$sora-700;
        font-size: 32px;
        line-height: 34.9px;
    }
    p {
        color: variables.$neutral-color-4;
        font-family: variables.$arial-400;
        font-size: 16px;
        line-height: 23.9px;
    }

    .linear-progress {
        width: 410px;
    }
}
