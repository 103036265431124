@import './variables.scss';

@font-face {
    font-family: $sora-700;
    src: url("../../public/fonts/Sora/Sora-Bold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $sora-600;
    src: url("../../public/fonts/Sora/Sora-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $sora-400;
    src: url("../../public/fonts/Sora/Sora-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $arial-700;
    src: url("../../public/fonts/Arial/Arial-Bold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $arial-400;
    src: url("../../public/fonts/Arial/Arial-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $poppins-600;
    src: url("../../public/fonts/Poppins/Poppins-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
}
