@use '../../styles/variables.scss';
@use 'sass:color';

.notification-container {

    .content {
        display: flex;
        flex-direction: column;
        text-align: center;

        .icon {
            align-items: center;
            display: flex;
            width: 64px;
            height: 64px;
            justify-content: center;
            align-items: center;
            border-radius: 104px;
            margin-bottom: 32px;

            &.error {
                border: 1px solid color.scale(variables.$semantic-color-1, $alpha: -80%);
                background: color.scale(variables.$semantic-color-1, $alpha: -90%);
            }
    
            &.success {
                border: 1px solid color.scale(variables.$semantic-color-4, $alpha: -80%);
                background: color.scale(variables.$semantic-color-4, $alpha: -90%);
            }
        }
    }

    button {
        width: 290px;
        &.error {
            align-self: center;
        }
    
        &.success {
            align-self: center;
        }
    }
    
    .error {
        align-items: center;
    }

    .success {
        align-items: center;
    }

    .close {
        cursor: pointer;
    }
}