@import '../../styles/variables.scss';

.table-header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .title {
        font-family: $sora-600;
        color: $neutral-color-7;
        text-align: start;
    }
    .subtitle{
        font-family: $arial-400;
        color: $neutral-color-7;
        text-align: start;
        font-size: .9rem;
    }

    .filters-container {
        display: flex;
        gap: 25px;
        .search {
            width: 369px;
        }
        .filter {
            display: flex;
            align-items: center;
            gap: 14px;
            cursor: pointer;
            p {
                color: $neutral-color-4;
                font-family: $arial-400;
                font-size: 16px;
                line-height: 23.9px;
            }
        }
    }

    &.with-full{
        .filters-container{
            width: 100%;
            .search{
                width: 100%;
            }
        }
    }
}