@use '../../styles/variables.scss';

.autocomplete-form {

    input {
        --webkit-appearance: none;
        appearance: none;
        height: 56px;
        width: 100%;
        font-size: 16px;
        padding: 0px 16px 0 16px;
        font-family: variables.$arial-400;
        color: variables.$neutral-color-7;
        background-color: #FFF;
        border: 1px solid variables.$neutral-color-3;
        border-radius: 100px;
        background-image: url('../../../public/img/icons/arrow-down.svg');
        background-repeat: no-repeat;
        background-position: center right 16px;
        background-size: 24px;

        &:focus {
            outline: none;
        }
        &:disabled{
            background-color: variables.$neutral-color-3;
        }
    }

    .default {
        color: variables.$neutral-color-3;
    }
    
}