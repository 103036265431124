@use '../../styles/variables.scss';
@use 'sass:color';

.option-card-container {
    display: flex;
    flex-direction: column;
    width: 258px;
    height: 204px;
    justify-content: center;
    align-items: center;
    gap: 22px;
    border-radius: 16px;
    box-shadow: 0px 0px 1px 0px rgba(49, 52, 63, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    background-color: #FFF;
    cursor: pointer;
}
