@import '../../styles/variables.scss';

.paginate-container {
    display: flex;
    align-items: center;
    height: 48px;
    gap: 14px;

    img {
        cursor: pointer;
    }

    p {
        color: $neutral-color-4;
        text-align: right;
        font-family: $arial-400;
        font-size: 12px;
        line-height: 23.9px;
    }

    .counter {
        display: flex;
        gap: 5px
    }
}